@tailwind base;
@tailwind components;
@tailwind utilities;

*,
::before,
::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
}

.custom-select .ant-select-selector {
  border-radius: 10px !important;
  min-height: 42px !important;
}

.no-scrollbar {
  overflow-y: auto;
}

/* For Chrome, Edge, and Safari */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* For Firefox */
.no-scrollbar {
  scrollbar-width: none;
  max-height: 40px !important;
}
.vertical-scrollbar::-webkit-scrollbar {
  height: 8px;
  border-radius: 8px;
}

.vertical-scrollbar::-webkit-scrollbar-thumb {
  background-color: #dbdbec;
  border-radius: 8px;
}

.tag-scroll::-webkit-scrollbar {
  width: 8px;
  border-radius: 8px;
}

.tag-scroll::-webkit-scrollbar-thumb {
  background-color: #dbdbec;
  border-radius: 8px;
}

#inbox-input .ql-toolbar {
  background-color: #ebedf7;
  border: none;
  border-radius: 4px;
  padding: 4px;
  box-shadow: 0px 0px 4px 0px #00000040;
  position: absolute;
  bottom: 100%;
  left: 20px;
  display: none;
  z-index: 50;
}

#inbox-input .ql-container {
  border: none;
  width: 100%;
}

#inbox-input .ql-editor {
  padding: 0;
  min-height: 32px;
  max-height: 40px;
  overflow-y: auto;
  width: 100%;
}

#inbox-input .ql-editor::-webkit-scrollbar {
  display: none;
}

#inbox-input .ql-editor {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

#inbox-input .ql-editor.ql-blank::before {
  left: 0;
}

#inbox-input .ql-formats {
  margin-right: 0;
}

#im-text-message * {
  padding: 0;
  margin: 0;
}

/* custom modal  */
.customModal .ant-modal-content {
  background-color: #f5f6fc !important;
  border-radius: 15px !important;
}
/* custom select  */
.custom-select .ant-select-single .ant-select-selector {
  border-radius: 10px !important;
}

/* custom input placeholder */
.custom-input::placeholder,
.custom-select .ant-select-selection-placeholder,
.custom-textArea .adm-text-area-element::placeholder {
  color: #c5c8d9 !important;
  font-size: 12px !important;
  letter-spacing: 1px !important;
}
/* text area  */
.custom-textArea .adm-text-area-element {
  font-size: 12px;
  letter-spacing: 0.5px !important;
  font-weight: 500;
}

/* integration modal */
.integration-modal .ant-modal-content {
  border-radius: 10px;
}
.integration-modal .ant-modal-content .ant-modal-header {
  background-color: transparent !important;
}
.custom-table .ant-table-footer {
  background-color: #ffffff;
}

.no-active-menu .ant-menu-item-selected,
.no-active-menu .ant-menu-item-active {
  color: inherit !important;
}

.no-active-menu .ant-menu {
  background-color: transparent !important;
}
